import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { some, isEmpty } from 'lodash';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { generateQrCode } from '../../../../communication/qr';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import ErrorModal from '../../../../components/errorModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalPlain2 from '../../common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../common/previewInvoiceModalPlainPenguin';
import PreviewInvoiceModalEaster from '../../common/previewInvoiceModalEaster';
import useUserStore from '../../../../store/user';
import { Invoice } from '../../common/style';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import WebFooter from '../../common/footer/webFooter';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import SendModal from '../sendModal';
import { countTotalProforma } from '../../common/countTotal';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import getAllClients from '../../../myBusiness/clients/getAllClients';
import { toast } from 'react-toastify';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../../myBusiness/clients/warningCard';
import useUnitsStore from '../../../../store/measurementUnits';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import useYearsStore from '../../../../store/years';
import PreviewInvoiceModalWomensDay from '../../common/previewInvoiceModalWomensDay';

const EditProforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const navigate = useNavigate();
    const proformaId = useParams().proformaId;
    const token = getAccessToken();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const { units } = useUnitsStore();

    const firstRender = useRef(true);

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [previewModal, setPreviewModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const [qrCode, setQrCode] = useState('');
    const [clickedClient, setClickedClient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const { isMobile } = useResponsive();
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});
    const [clients, setClients] = useState<ObjectKeys>([]);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const [proformaData, setProformaData] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        currency: 'RSD',
        iban: '',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        swift: '',
        trading_place: '',
    });

    useEffect(() => {
        setLoading(true);
        if (proformaId) {
            communication.getProformaById(proformaId).then((res: ObjectKeys) => {
                if (res && res.data && res.data.data) {
                    const quotationData = res.data.data;
                    setProformaData({
                        ...quotationData,
                        memorandums: quotationData.memorandums?.data?.map((item: { id: string }) => item.id),
                    });
                    if (quotationData?.client?.data) {
                        const emails =
                            quotationData.client.data.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
                        setClientEmails(
                            emails.length > 0
                                ? emails
                                : quotationData.client.data.email
                                ? [quotationData.client.data.email]
                                : [],
                        );
                    } else {
                        setClientEmails([]);
                    }
                    const newUnits = res?.data.data.services
                        .map((item: ObjectKeys) => item.pivot.measurement_unit)
                        .filter((unit: any) => !units.includes(unit));

                    useUnitsStore.setState({ units: [...units, ...newUnits] });
                    setLoading(false);
                    agency &&
                        quotationData.type !== 'foreign' &&
                        handleGenerateQRCode(
                            agency,
                            quotationData.client?.data?.company_name,
                            quotationData.value,
                            quotationData.id,
                            quotationData.bank_account,
                            quotationData.currency,
                            true,
                        );
                }
            });
        }
    }, [proformaId]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setProformaData((prev: ObjectKeys) => {
                const newProformaData = { ...prev };
                newProformaData.client_id = newCreatedClient && newCreatedClient?.id;
                newProformaData.client.data = newCreatedClient;
                return newProformaData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmails([]);
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, proformaData.type === 'national' ? 0 : 1);
                    setDeleteClientModal(false);
                    setLoading(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setProformaData((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
            })
            .catch((error: ObjectKeys) => {
                setLoading(false);
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
    };
    const updateYears = (): void => {
        communication
            .getQuotationYears()
            .then((res: ObjectKeys) => {
                useYearsStore.setState({ quotationYears: res?.data?.years });
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    useYearsStore.setState({ quotationYears: [] });
                }
            });
    };

    const handleProformaDelete = async (): Promise<void> => {
        setDeleteInvoiceModal(false);
        setLoading(true);
        communication
            .deleteProforma(proformaData.id)
            .then((res: ObjectKeys) => {
                if (res) {
                    setLoading(false);
                    navigate('/proforma');
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                    setLoading(false);
                }
            });
    };

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData.name,
                clientData,
                amount,
                proformaNumber,
                bankAccount,
                currency,
                convert,
                `00${proformaNumber?.replace('/', '-')}`,
                '221',
            ).then((response: ObjectKeys | any) => {
                if (response.i) setQrCode(response.i);
                else setQrCode('');
            });
        } catch (error: any) {
            setQrCode('');
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        if (client) {
            const emails = client.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
            setClientEmails(emails.length > 0 ? emails : client.email ? [client.email] : []);
        } else {
            setClientEmails([]);
        }
        setProformaData((prev: ObjectKeys) => {
            const newProformaData = { ...prev };
            newProformaData.client_id = client?.id;
            newProformaData.client.data = client;
            return newProformaData;
        });
        agency &&
            proformaData.type !== 'foreign' &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                proformaData.value,
                proformaData.id,
                proformaData.bank_account,
                proformaData.currency,
                true,
            );
    };

    useEffect(() => {
        const matchingClient = Array.isArray(clients.data)
            ? clients.data.find((client: ObjectKeys) => client.id === proformaData.client.data.id)
            : null;
        matchingClient && handleSelectedClient(matchingClient);
    }, [clients]);

    const isProformaValid = (): boolean => {
        if (!proformaData.services || proformaData.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            return false;
        }

        if (proformaData.quotation_number.length === 0) {
            setModalMessage(t('pages.editInvoice.missingProformaNumber').text);
            setSuccess(true);
            return false;
        }

        if (
            some(
                proformaData.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            return false;
        }

        if (!proformaData.client_id || Object.keys(proformaData.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            return false;
        }

        if (proformaData.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            return false;
        }
        if (
            !validateIbanSwift(
                proformaData.iban,
                proformaData.swift,
                proformaData.bank_account_id,
                returnBankCodeFromId(proformaData.bank_id) === '000' || proformaData.type !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            return false;
        }

        return true;
    };

    const handleUpdateProforma = async (): Promise<any> => {
        setChangeHappened(false);
        if (!isProformaValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const updateProforma = { ...proformaData };

            updateProforma.services = updateProforma.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity.replace(',', '.'),
                price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                discount: service.pivot.discount,
                description: service.pivot.description,
            }));
            updateProforma.value = Number(countTotalProforma(updateProforma.services).replace(',', '.'));
            updateProforma.trading_date = moment(updateProforma.trading_date).format('YYYY-MM-DD').toString();
            updateProforma.rate = Number(updateProforma.rate);
            updateProforma.paid_date = moment(
                updateProforma.paid_date ? updateProforma.paid_date : new Date('1970-01-01'),
            )
                .format('YYYY-MM-DD')
                .toString();
            updateProforma.quotation_date = moment(updateProforma.quotation_date).format('YYYY-MM-DD').toString();
            updateProforma.custom_number = false;
            updateProforma.is_custom = false;
            updateProforma.custom_text = false;
            updateProforma.invoice_per_owner = true;
            updateProforma.auto_increment = true;
            updateProforma.cancel_number = false;
            updateProforma.cancelled = false;
            delete updateProforma.quotation_number;
            // updateProforma.flag_updated_quotation_number = true;
            const res = await communication.editProforma(proformaData.id, updateProforma);

            if (res.status === 200) {
                setLoading(false);
                setChangeHappened(false);
                updateYears();
                return res?.data?.data;
            }
        } catch (error: any) {
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    //Proforma Download
    const handleDownloadProforma = async (): Promise<void> => {
        const updatedProforma = await handleUpdateProforma();
        if (!updatedProforma) return;
        if (updatedProforma.type === 'foreign') {
            communication
                .downloadProforma(
                    proformaData.id,
                    token,
                    proformaData.quotation_number,
                    proformaData.currency !== 'RSD',
                )
                .then(() => {
                    navigate('/proforma');
                    setLoading(false);
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                    setOpenErrorModal(true);
                    setLoading(false);
                });
        } else {
            try {
                generateQrCode(
                    agency.name,
                    proformaData.client ? proformaData.client.data.company_name : '',
                    proformaData.value_in_rsd ? proformaData.value_in_rsd.toString() : '0',
                    proformaData.quotation_number ? proformaData.quotation_number : '',
                    proformaData.bank_account,
                    proformaData.currency,
                    true,
                    proformaData.quotation_number ? `00${proformaData.quotation_number?.replace('/', '-')}` : null,
                    '221',
                )
                    .then(async (res: ObjectKeys | any) => {
                        if (res) {
                            communication
                                .downloadProforma(
                                    proformaData.id,
                                    token,
                                    proformaData.quotation_number,
                                    proformaData.currency !== 'RSD',
                                    res?.i ? res?.i : '',
                                )
                                .then(() => {
                                    navigate('/proforma');
                                    setLoading(false);
                                })
                                .catch((error: ObjectKeys) => {
                                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                    setOpenErrorModal(true);
                                    setLoading(false);
                                });
                        }
                    })
                    .catch(() => {
                        communication
                            .downloadProforma(
                                proformaData.id,
                                token,
                                proformaData.quotation_number,
                                proformaData.currency !== 'RSD',
                            )
                            .then(() => {
                                navigate('/proforma');
                                setLoading(false);
                            })
                            .catch((error: ObjectKeys) => {
                                setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                setOpenErrorModal(true);
                                setLoading(false);
                            });
                    });
            } catch (error: any) {
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
            }
        }
    };

    //Proforma Print
    const handleViewInvoicePDF = async (): Promise<void> => {
        const updatedProforma = await handleUpdateProforma();
        if (!updatedProforma) return;
        if (updatedProforma.type === 'foreign') {
            communication
                .printProforma(proformaData.id, token, proformaData.quotation_number, proformaData.currency !== 'RSD')
                .then((resp: ObjectKeys) => {
                    const url = window.URL.createObjectURL(resp.data);
                    const printWindow = window.open(url, '_blank');
                    if (printWindow) {
                        printWindow.onload = () => {
                            printWindow.focus();
                            printWindow.print();
                        };
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                    setOpenErrorModal(true);
                    setLoading(false);
                });
            navigate('/proforma');
        } else {
            try {
                generateQrCode(
                    agency.name,
                    proformaData.client ? proformaData.client.data.company_name : '',
                    proformaData.value_in_rsd ? proformaData.value_in_rsd.toString() : '0',
                    proformaData.quotation_number ? proformaData.quotation_number : '',
                    proformaData.bank_account,
                    proformaData.currency,
                    true,
                    proformaData.quotation_number ? `00${proformaData.quotation_number?.replace('/', '-')}` : null,
                    '221',
                )
                    .then(async (res: ObjectKeys | any) => {
                        if (res) {
                            communication
                                .printProforma(
                                    proformaData.id,
                                    token,
                                    proformaData.quotation_number,
                                    proformaData.currency !== 'RSD',
                                    res?.i ? res?.i : '',
                                )
                                .then((resp: ObjectKeys) => {
                                    const url = window.URL.createObjectURL(resp.data);
                                    const printWindow = window.open(url, '_blank');
                                    if (printWindow) {
                                        printWindow.onload = () => {
                                            printWindow.focus();
                                            printWindow.print();
                                        };
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                    setOpenErrorModal(true);
                                    setLoading(false);
                                });
                            navigate('/proforma');
                        }
                    })
                    .catch(() => {
                        communication
                            .printProforma(
                                proformaData.id,
                                token,
                                proformaData.quotation_number,
                                proformaData.currency !== 'RSD',
                            )
                            .then((resp: ObjectKeys) => {
                                const url = window.URL.createObjectURL(resp.data);
                                const printWindow = window.open(url, '_blank');
                                if (printWindow) {
                                    printWindow.onload = () => {
                                        printWindow.focus();
                                        printWindow.print();
                                    };
                                }
                            })
                            .catch((error: ObjectKeys) => {
                                setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                setOpenErrorModal(true);
                                setLoading(false);
                            });
                        navigate('/proforma');
                    });
            } catch (error: any) {
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
            }
        }
    };

    // Create invoice from proforma
    const handleCreateInvoiceFromProforma = async (): Promise<void> => {
        const proformaDataToSend = await handleUpdateProforma();
        if (!proformaDataToSend) return;
        const proformaServices = proformaDataToSend.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = false;
        const updatedProforma = await handleUpdateProforma();
        if (updatedProforma) {
            navigate(`/invoices/copy/${proformaData.id}/quotation`);
        }
    };

    // Create advance from proforma
    const handleCreateAdvanceFromProforma = async (): Promise<void> => {
        const proformaDataToSend = await handleUpdateProforma();
        if (!proformaDataToSend) return;
        const proformaServices = proformaDataToSend.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = false;
        navigate(`/advance-invoices/copy/${proformaData.id}/quotation`);
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            if (proformaData.services && proformaData.services.length > 0 && !isEmpty(proformaData)) {
                setChangeHappened(true);
            }
        }
    }, [proformaData]);

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            {loading && <Loader />}
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={proformaData.type === 'national' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={proformaData.type === 'national' ? 0 : 1}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            setChangeHappened(false);
                            handleProformaDelete();
                        }}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.newProforma.deleteProforma').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        proforma={proformaData}
                        t={t}
                        id={proformaData.id}
                        success={() => {
                            setSendModal(false);
                            navigate('/proforma');
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmails={clientEmails}
                        setProforma={setProformaData}
                        handleUpdateProforma={handleUpdateProforma}
                        qrCode={qrCode}
                        modalVisible={sendModal}
                        editInvoice={(updatedProforma: ObjectKeys) => {
                            setProformaData(updatedProforma);
                        }}
                    />
                </Sidebar>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'womensDay' && (
                        <PreviewInvoiceModalWomensDay
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'easter' && (
                        <PreviewInvoiceModalEaster
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <div className="new-proforma page">
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                )}
                <InvoiceClients
                    t={t}
                    setClients={setClients}
                    clients={clients}
                    setAddClientModal={setAddClientModal}
                    agency={agency}
                    handleSelectedClient={handleSelectedClient}
                    addClientModal={addClientModal}
                    deleteClientModal={deleteClientModal}
                    editClientModal={editClientModal}
                    setClickedClient={setClickedClient}
                    setEditClientModal={setEditClientModal}
                    setDeleteClientModal={setDeleteClientModal}
                    invoice={proformaData}
                    type={
                        proformaData?.type === 'national'
                            ? 'domestic'
                            : proformaData?.type === 'foreign'
                            ? 'foreign'
                            : ''
                    }
                    loading={(isLoading: boolean) => setLoading(isLoading)}
                />
                {agency && proformaData && (
                    <Invoice>
                        {proformaData?.type === 'national' ? (
                            <InvoiceEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={'domestic'}
                                setLoading={setLoading}
                                handleQrCode={(qr: string) => setQrCode(qr)}
                                // qrCode={qrCode}
                            />
                        ) : (
                            <InvoiceForeignEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        total_for_payment: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={'foreign'}
                                setLoading={setLoading}
                                clientChanged={clientChanged}
                                handleChangeHappend={(change: boolean) => {
                                    setChangeHappened(change);
                                }}
                            />
                        )}
                    </Invoice>
                )}
                {isMobile ? (
                    <MobileFooter
                        handleUpdateInvoice={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                ) : (
                    <WebFooter
                        t={t}
                        handleUpdateInvoice={() => {
                            handleUpdateProforma()
                                .then((res: ObjectKeys) => {
                                    if (res) {
                                        setChangeHappened(false);
                                        navigate('/proforma');
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    console.error(error);
                                });
                        }}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                )}
            </div>
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
        </>
    );
};
export default EditProforma;

import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import { toast } from 'react-toastify';
import useTheme from '../../../../hooks/useTheme';

type Props = {
    t: Function;
    invoiceNumber: string;
    clickedInvoice: ObjectKeys;
    closeGlobalModal: () => void;
    setInvoices: Dispatch<SetStateAction<ObjectKeys>>;
    selectedUserId: string;
    invoiceId: string;
    onDeleteInvoice: (confirmed: boolean) => void;
};

const DeleteModal: FunctionComponent<Props> = ({
    t,
    invoiceNumber,
    closeGlobalModal,
    invoiceId,
    onDeleteInvoice,
    clickedInvoice,
}) => {
    const isDarkTheme = useTheme();

    const handleDeleteInvoice = (): void => {
        closeGlobalModal();
        communication
            .deleteInvoice(invoiceId)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    toast.success(res.data);
                    onDeleteInvoice(true);
                }
            })
            .catch((err: ObjectKeys) => {
                toast.error(err.response.data.message);
            });
    };
    return (
        <>
            <DeleteModalWrapper>
                <ModalText>
                    {clickedInvoice.status !== 'open' ? (
                        <>
                            <h4>{`${t('pages.advanceInvoices.deleteModal.title2').text} ${invoiceNumber}?`}</h4>
                            <p>{t('pages.invoices.deleteModal.description').text}</p>
                        </>
                    ) : (
                        <h4>{t('pages.invoices.deleteModal.title2').text}</h4>
                    )}
                </ModalText>
                <ButtonContainer isDarkTheme={isDarkTheme}>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        onClick={() => {
                            handleDeleteInvoice();
                        }}
                        size={'100%'}
                    >
                        {t('input.yes').text}
                    </Button>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        onClick={() => closeGlobalModal()}
                        size={'100%'}
                    >
                        {t('input.no').text}
                    </Button>
                </ButtonContainer>
            </DeleteModalWrapper>
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const DeleteModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div<DarkThemeProps>`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-right: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#fff')};
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 25px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;

export default DeleteModal;

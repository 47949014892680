import { FunctionComponent, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faEllipsisVertical,
    faMinusCircle,
    faRefresh,
    faBell,
    faInfoCircle,
    faPrint,
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { ObjectKeys } from '../../types/objectKeys';
import { Link } from 'react-router-dom';
import useDropdown from '../invoiceCard/useDropdown';
import Dropdown from '../dropdown';
import getStatus from '../invoiceCard/getStatus';
import { Icon } from '../invoiceCard/invoiceActionsWithoutDropdown';
import useProfileStore from '../../store/profile';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../pages/pausal/common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../pages/pausal/common/previewInvoiceModalPlainPenguin';
import PreviewInvoiceModalEaster from '../../pages/pausal/common/previewInvoiceModalEaster';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import useResponsive from '../../hooks/responsive/useResponsive';
import useOutsideClick from '../../hooks/useOutsideClick';
import {
    StyledInvoiceCard,
    CardContent,
    InfoWrapper,
    ActionWrapper,
    Action,
    DropdownContainer,
    ActionWithoutDropdown,
    ContentWrapper,
    CheckboxSection,
} from '../invoiceCard/style';
import Tooltip from '../invoiceCard/tooltip';
import TooltipMain from '../../pages/pausal/common/statusTooltip';
import PaymentDropdown from '../invoiceCard/paymentDropdown';
import Checkbox from '../checkbox';
import { useLocation } from 'react-router-dom';
import useTheme from '../../hooks/useTheme';

export enum EInvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoices = 'advanceInvoices',
}
interface Props {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
    paymentInfo?: Function;
    checkedInvoice?: Function;
    cardIdArray?: string[] | [];
    handleViewPDF?: Function;
}
const EInvoiceCard: FunctionComponent<Props> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleRefresh,
    cardDisplay,
    cardData,
    eInvoice,
    handleRemovePayments,
    handleUpdatePayment,
    handleDeletePayment,
    dropdownDirection = 'down',
    paymentInfo,
    checkedInvoice,
    cardIdArray,
    handleViewPDF,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { isLaptopAndDown } = useResponsive();
    const role = localStorage.getItem('role');

    const isAccountActive = role === 'admin' ? true : profile?.active === 1 ? true : false;

    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);

    const isAdvance = cardData?.is_prepaid ? true : false;
    const eAdvanceWithPrepaids = cardData.is_einvoice && cardData?.prepaid_invoices?.length > 0 ? false : true;
    const isCanceledPaidInvoice = cardData.status === 'paid' && cardData.cancelled ? false : true;

    const eInvoiceStatusStorno = cardData?.eInvoice?.data?.status === 'Storno' ? true : false;

    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;
    const [qrCode, setQrCode] = useState('');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    // const ref2 = useRef<HTMLDivElement>(null);
    // const { handleClickOutside2 } = useOutsideClick(ref2, setShowPaymentDropdown);

    const statusTooltipRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const { handleTooltipClickOutside } = useOutsideClick(statusTooltipRef, setShowTooltip);

    const { pathname } = useLocation();
    const isEAdvanceInvoices = pathname.startsWith('/eadvance-invoices');

    const isDarkTheme = useTheme();

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const handlePaymentInfo = (): void => {
        if (paymentInfo) {
            setShowPaymentDropdown(!showPaymentDropdown);
            paymentInfo();
        }
    };

    const viewInvoiceInPDF = (): void => {
        if (handleViewPDF) {
            handleViewPDF();
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        isAdvance,
        eInvoiceStatusStorno ? undefined : handlePaymentInfo,
        viewInvoiceInPDF,
        undefined,
    );
    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        handleReminder,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        isAdvance,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );
    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        isAdvance,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );
    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        isAdvance,
        eInvoiceStatusStorno ? undefined : handlePaymentInfo,
        viewInvoiceInPDF,
        undefined,
    );
    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        undefined,
        eInvoice,
        isAdvance,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    // On outside click close the menu
    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setDropdownVisible(false);
        }
    };

    const handleCardClick = (): void => {
        if (!isAccountActive) {
            handleDownload && handleDownload(cardData.id);
        } else {
            setPreviewModal(true);
            if (cardData.type !== 'foreign' && cardData.is_prepaid !== 1) {
                handleGenerateQRCode(
                    agency.full_name,
                    cardData?.client?.data?.company_name,
                    cardData?.total_for_payment_in_rsd,
                    cardData?.invoice_number ? cardData?.invoice_number : cardData?.quotation_number,
                    cardData?.bank_account,
                    cardData?.currency,
                    true,
                );
            }
        }
    };

    const [tooltipDisplayText, setTooltipDisplayText] = useState<string>('');

    const handleCardStatusSubmission = (e: any, invoiceNumber: string, cancelledInvoiceNumber: string | null): void => {
        if (!isAdvance) {
            const status = cardData?.status || '';
            if (status) {
                e.stopPropagation();
                if (status === 'sent' || status === 'issued') {
                    handlePayment && handlePayment();
                } else if (status === 'paid' || status === 'partially') {
                    if (cardData?.cancelled) {
                        setShowTooltip(true);
                        if (invoiceNumber) {
                            setTooltipDisplayText(
                                `${t('pages.invoiceCard.status.stornoStatus').text} ${invoiceNumber ?? invoiceNumber}`,
                            );
                        } else {
                            setTooltipDisplayText(t('pages.invoiceCard.status.invoiceCancelled').text);
                        }
                    } else {
                        handlePaymentInfo && handlePaymentInfo();
                    }
                } else if (status === 'open') {
                    setShowTooltip(true);
                    setTooltipDisplayText(t('pages.invoiceCard.status.openNotCharged').text);
                } else if (status === 'cancelled') {
                    setShowTooltip(true);

                    if (cancelledInvoiceNumber) {
                        setTooltipDisplayText(
                            `${t('pages.invoiceCard.status.cancelledStatus').text} ${cancelledInvoiceNumber}`,
                        );
                    } else {
                        setTooltipDisplayText(t('pages.invoiceCard.status.stornoDefault').text);
                    }
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <ContentWrapper>
            {!isEAdvanceInvoices && (
                <CheckboxSection>
                    {isAccountActive && (
                        <CheckboxSection>
                            <Checkbox
                                onChange={() => {
                                    checkedInvoice && checkedInvoice(cardData.id);
                                }}
                                defaultChecked={
                                    cardIdArray?.find((separateId) => separateId === cardData.id) ? true : false
                                }
                            />
                        </CheckboxSection>
                    )}
                </CheckboxSection>
            )}
            <StyledInvoiceCard
                key={cardData.id}
                className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}
                isDarkTheme={isDarkTheme}
            >
                {previewModal && (
                    <Modal
                        width="700px"
                        className="preview-modal"
                        modalVisible={true}
                        hasCloseButton={true}
                        closeModal={() => {
                            setPreviewModal(false);
                        }}
                    >
                        {preferredTemplate === 'plain' && (
                            <PreviewInvoiceModalPlain
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={cardData.original_data.agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )} */}
                        {preferredTemplate === 'business' && (
                            <PreviewInvoiceModalBusiness
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'invoicePro' && (
                            <PreviewInvoiceModalInvoicePro
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'plain_2' && (
                            <PreviewInvoiceModalPlain2
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'santa' && (
                            <PreviewInvoiceModalPlainSanta
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'penguin' && (
                            <PreviewInvoiceModalPlainPenguin
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'easter' && (
                            <PreviewInvoiceModalEaster
                                agency={cardData.original_data.agency}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                    </Modal>
                )}
                <CardContent className="card-content cursor-pointer" isDarkTheme={isDarkTheme}>
                    <InfoWrapper
                        onClick={() => {
                            handleCardClick();
                        }}
                        className={`info-wrapper ${cardDisplay === 'grid' && 'grid-view'}`.trim()}
                        isDarkTheme={isDarkTheme}
                    >
                        <div className="name-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                            <div className="name">{cardData?.client?.data?.company_name}</div>
                        </div>
                        <div className="number-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.prefix').text}</div>
                            <div className="prefix">{cardData?.prefix}</div>
                        </div>
                        <div className="number-col">
                            <div className="label">
                                {t('pages.invoiceCard.infoWrapper.number').text +
                                    ': ' +
                                    (type === 'invoice' ? t('pages.invoiceCard.infoWrapper.invoice').text : '')}
                            </div>
                            <div className="number">
                                {cardData?.invoice_number
                                    ? cardData?.invoice_number
                                    : getStatus(cardData?.status?.toLowerCase(), t)}
                            </div>
                        </div>
                        <div className="date-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                            <div className="date">{moment(cardData?.invoice_date)?.format('DD.MM.YYYY')}</div>
                        </div>
                        <div className="amount-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                            <div className="amount">
                                {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                            </div>
                        </div>
                        <div className="statusAndAction">
                            <div className="e-invoice-status-wrapper">
                                <TooltipMain
                                    text={tooltipDisplayText}
                                    isHtml={true}
                                    position="top"
                                    show={showTooltip}
                                    refProps={handleTooltipClickOutside}
                                    label={
                                        <div className="status-col">
                                            <div className="label">
                                                {t('pages.invoiceCard.infoWrapper.status').text}
                                            </div>
                                            <div className={`status ${cardData?.status} ${isAdvance && 'noHover'}`}>
                                                <span
                                                    onClick={(e) =>
                                                        handleCardStatusSubmission(
                                                            e,
                                                            cardData?.cancelled_invoice_number,
                                                            cardData?.original_invoice_number,
                                                        )
                                                    }
                                                >
                                                    {getStatus(cardData?.status?.toLowerCase(), t)}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                />
                                {eInvoice &&
                                    cardData?.eInvoice &&
                                    (() => {
                                        const statusRaw = cardData?.eInvoice?.data?.status;
                                        const status = statusRaw ? statusRaw.toLowerCase() : null;
                                        return (
                                            <div className="status-col">
                                                <div className="label">
                                                    {isAdvance
                                                        ? t('pages.eInvoices.status.title_eAdvance').text
                                                        : t('pages.eInvoices.status.title').text}
                                                </div>
                                                <div className={`estatus ${status} ${isAdvance && 'noHover'}`}>
                                                    <span>{getStatus(status, t, eInvoice)}</span>
                                                </div>
                                            </div>
                                        );
                                    })()}
                            </div>
                            {isAccountActive ? (
                                <ActionWrapper className="mainActionWrapper" onClick={(e) => e.stopPropagation()}>
                                    <div className="label"></div>
                                    <div className="action">
                                        {isLaptopAndDown ? (
                                            <Action ref={ref}>
                                                <FontAwesomeIcon
                                                    icon={faEllipsisVertical}
                                                    style={{ color: colors.gray }}
                                                    className="cursor-pointer dots"
                                                    onClick={() => {
                                                        handleClick(cardData?.id);
                                                        setDropdownVisible(!dropdownVisible);
                                                    }}
                                                />
                                                {dropdownVisible && (
                                                    <DropdownContainer
                                                        onClick={(e: MouseEvent | any) => {
                                                            handleClickOutside(e);
                                                            setDropdownVisible(false);
                                                        }}
                                                        className={dropdownDirection}
                                                    >
                                                        <Dropdown
                                                            arrowRight="4px"
                                                            caretDown={dropdownDirection === 'up'}
                                                            itemList={
                                                                cardData?.status === 'paid'
                                                                    ? paidDropdown
                                                                    : cardData?.status === 'sent'
                                                                    ? sentDropdown
                                                                    : cardData?.status === 'open'
                                                                    ? draftDropdown
                                                                    : cardData?.status === 'partially'
                                                                    ? partiallyDropdown
                                                                    : cardData?.status === 'cancelled' ||
                                                                      cardData?.status === 'closed'
                                                                    ? cancelledDropdown
                                                                    : []
                                                            }
                                                        />
                                                    </DropdownContainer>
                                                )}
                                            </Action>
                                        ) : (
                                            <ActionWithoutDropdown onClick={(e) => e.stopPropagation()}>
                                                {cardData?.status === 'paid' ? (
                                                    <>
                                                        <Tooltip text={t('pages.editInvoice.print').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faPrint}
                                                                    style={{
                                                                        color: isDarkTheme ? colors.white : colors.gray,
                                                                    }}
                                                                    onClick={() => handleViewPDF && handleViewPDF()}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                        {eInvoice && cardData?.eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faRefresh}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleRefresh && handleRefresh(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.download').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.copy').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faClone}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        </Link>

                                                        {/* {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )} */}

                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEnvelope}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleSend && handleSend(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        <Tooltip>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faE}
                                                                    style={{ color: colors.gray }}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                        {!eInvoiceStatusStorno ? (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.info').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faInfoCircle}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            paymentInfo && handlePaymentInfo()
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        ) : null}
                                                    </>
                                                ) : cardData?.status === 'sent' ? (
                                                    <>
                                                        <Tooltip text={t('pages.editInvoice.print').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faPrint}
                                                                    style={{
                                                                        color: isDarkTheme ? colors.white : colors.gray,
                                                                    }}
                                                                    onClick={() => handleViewPDF && handleViewPDF()}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                        {eInvoice && cardData?.eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faRefresh}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleRefresh && handleRefresh(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.download').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.copy').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faClone}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        </Link>
                                                        {cardData?.eInvoice?.data?.status === 'Sending' ? null : (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.cancel').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faMinusCircle}
                                                                        style={{ color: colors.gray }}
                                                                        onClick={(e) => handleCancel && handleCancel(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        {!eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashCan}
                                                                        style={{ color: colors.danger }}
                                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEnvelope}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleSend && handleSend(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        {!isAdvance && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.payment').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faCheckCircle}
                                                                        style={{ color: colors.green }}
                                                                        onClick={(e) =>
                                                                            handlePayment && handlePayment(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faE}
                                                                    style={{ color: colors.gray }}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        {!isAdvance && (
                                                            <Tooltip
                                                                text={t('pages.invoices.sendModal.send_reminder').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faBell}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleReminder && handleReminder(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : cardData?.status === 'partially' ? (
                                                    <>
                                                        <Tooltip text={t('pages.editInvoice.print').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faPrint}
                                                                    style={{
                                                                        color: isDarkTheme ? colors.white : colors.gray,
                                                                    }}
                                                                    onClick={() => handleViewPDF && handleViewPDF()}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                        {eInvoice && cardData?.eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faRefresh}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleRefresh && handleRefresh(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.download').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.copy').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faClone}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        </Link>

                                                        {!eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashCan}
                                                                        style={{ color: colors.danger }}
                                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEnvelope}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleSend && handleSend(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        {!isAdvance && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.payment').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faCheckCircle}
                                                                        style={{ color: colors.green }}
                                                                        onClick={(e) =>
                                                                            handlePayment && handlePayment(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        {eInvoice && (
                                                            <Tooltip>
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faE}
                                                                        style={{ color: colors.gray }}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        {!eInvoiceStatusStorno ? (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.info').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faInfoCircle}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            paymentInfo && handlePaymentInfo()
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        ) : null}
                                                    </>
                                                ) : cardData?.status === 'cancelled' ||
                                                  cardData?.status === 'closed' ? (
                                                    <>
                                                        <Tooltip text={t('pages.editInvoice.print').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faPrint}
                                                                    style={{
                                                                        color: isDarkTheme ? colors.white : colors.gray,
                                                                    }}
                                                                    onClick={() => handleViewPDF && handleViewPDF()}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                        {eInvoice && cardData?.eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faRefresh}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleRefresh && handleRefresh(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.download').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.copy').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faClone}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        </Link>

                                                        {!eInvoice && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashCan}
                                                                        style={{ color: colors.danger }}
                                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEnvelope}
                                                                    style={{ color: colors.blue }}
                                                                    onClick={(e) => handleSend && handleSend(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>

                                                        {eInvoice && (
                                                            <Tooltip>
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faE}
                                                                        style={{ color: colors.gray }}
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </ActionWithoutDropdown>
                                        )}
                                    </div>
                                </ActionWrapper>
                            ) : (
                                <></>
                            )}
                        </div>
                    </InfoWrapper>
                </CardContent>
                {showPaymentDropdown &&
                !cardData?.cancelled &&
                cardData?.payments?.data &&
                cardData?.payments?.data.length > 0 ? (
                    <PaymentDropdown
                        cardData={cardData}
                        t={t}
                        handlePayment={handlePayment}
                        type={type}
                        handleUpdatePayment={handleUpdatePayment}
                        handleDeletePayment={handleDeletePayment}
                        cardDisplay={cardDisplay}
                    />
                ) : null}
            </StyledInvoiceCard>
        </ContentWrapper>
    );
};

export default EInvoiceCard;

import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, some } from 'lodash';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import ErrorModal from '../../../../components/errorModal';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import SendModal from '../../common/sendModal';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import WebFooter from '../../common/footer/webFooter';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../common/previewInvoiceModalPlainPenguin';
import PreviewInvoiceModalWomensDay from '../../common/previewInvoiceModalWomensDay';
import PreviewInvoiceModalEaster from '../../common/previewInvoiceModalEaster';
import useUserStore from '../../../../store/user';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import { Body, Container, Invoice } from '../../common/style';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import useYearsStore from '../../../../store/years';
import countTotal from '../../common/countTotal';
import getAllAInvoices from '../getAllAInvoices';
import getAllClients from '../../../myBusiness/clients/getAllClients';
import { toast } from 'react-toastify';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../../myBusiness/clients/warningCard';
import useUnitsStore from '../../../../store/measurementUnits';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import useResponsive from '../../../../hooks/responsive/useResponsive';

const CopyEInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { agency } = useAgencyStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const token = getAccessToken();
    const { pathname } = useLocation();
    const invoiceId = useParams().id;
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const isAdvance = pathname.includes('advance');

    const { units } = useUnitsStore();

    const firstRender = useRef(true);

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({});
    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pastYearModal, setPastYearModal] = useState(false);
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const [oldInvoiceDate, setOldInvoiceDate] = useState('');

    const { isMobile } = useResponsive();
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmails([]);
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, invoice.type === 'national' ? 0 : 1);
                    setDeleteClientModal(false);
                    setLoading(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setInvoice((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
            })
            .catch((error: ObjectKeys) => {
                setLoading(false);
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
    };

    const handleInvoiceDelete = async (): Promise<void> => {
        setDeleteInvoiceModal(false);
        setLoading(true);
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                navigate(-1);
                setLoading(false);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    const thisYear = parseInt(moment().format('YYYY'));

    const update = async (): Promise<any> => {
        try {
            setChangeHappened(false);
            setLoading(true);
            const updateInvoice = {
                ...invoice,
                auto_increment: true,
                cancel_number: false,
                cancelled: false,
                custom_number: false,
                custom_text: false,
                invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD').toString(),
                invoice_number: invoice.real_invoice_number,
                invoice_per_owner: true,
                is_custom: false,
                is_prepaid: true,
                paid_date: moment(invoice.paid_date).format('YYYY-MM-DD').toString(),
                prefix: 'A',
                rate: Number(invoice.rate),
                sef_invoice_number: invoice.sef_invoice_number ? invoice.sef_invoice_number : null,
                services: invoice.services.map((service: ObjectKeys, i: number) => ({
                    ...service,
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: Number(service.pivot.quantity.replace(',', '.')),
                    price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                    pivot: {
                        discount: 0,
                        index: i, // Add index to pivot
                        measurement_unit: service.pivot.measurement_unit,
                        price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                        quantity: service.pivot.quantity.replace(',', '.'),
                        total_price: service.pivot.total_price, // Ensure total_price is included
                        description: service.pivot.description,
                    },
                })),
                trading_date: moment(invoice.trading_date).format('YYYY-MM-DD').toString(),
                value: Number(countTotal(invoice.services).replace(',', '.')),
                value_in_rsd: Number(countTotal(invoice.services).replace(',', '.')),
            };
            const res = await communication.editInvoice(invoice.id, updateInvoice);

            if (res) {
                setLoading(false);
                setChangeHappened(false);
                updateYears();
                return res?.data?.data;
            }
        } catch (error: any) {
            const errorMsg = error.response?.data?.message || 'An unknown error occurred';
            setErrorMessage(errorMsg);
            setOpenErrorModal(true);
            setLoading(false);
            return { error: errorMsg }; // Return an error object
        }
    };

    const isInvoiceValid = async (): Promise<boolean> => {
        const real = invoice.real_invoice_number;
        if (!real || /^0+$/.test(real)) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingInvoiceNumber').text);
            setLoading(false);
            return false;
        }
        if (!invoice.services || invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            setLoading(false);
            return false;
        }

        if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (!invoice.client_id || Object.keys(invoice.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (invoice.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }
        if (
            !validateIbanSwift(
                invoice.iban,
                invoice.swift,
                invoice.bank_account_id,
                returnBankCodeFromId(invoice.bank_id) === '000' || invoice.type !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            setLoading(false);
            return false;
        }

        return true;
    };

    const handleUpdateInvoice = async (): Promise<any> => {
        setChangeHappened(false);
        const isValid = await isInvoiceValid();
        if (!isValid) throw new Error('Invalid invoice');
        return update();
    };

    const handleViewInvoicePDF = async (): Promise<void> => {
        try {
            await handleUpdateInvoice().then(async (newInvoice: any) => {
                if (newInvoice?.error) {
                    setErrorMessage(newInvoice.error);
                    setOpenErrorModal(true);
                    setLoading(false);
                    return;
                }
                setLoading(true);
                communication
                    .printInvoice(
                        invoice.id,
                        token,
                        newInvoice.future_invoice_number || invoice.invoice_number || '',
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    )
                    .then((resp: ObjectKeys) => {
                        setLoading(false);
                        updateYears();
                        navigate('/advance-invoices');
                        setLoading(false);
                        const url = window.URL.createObjectURL(resp.data);
                        const printWindow = window.open(url, '_blank');
                        if (printWindow) {
                            printWindow.onload = () => {
                                printWindow.focus();
                                printWindow.print();
                            };
                        }
                    })
                    .catch((error: any) => {
                        setLoading(false);
                        setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                        setOpenErrorModal(true);
                    });
            });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (invoiceId) {
            communication.getInvoice(invoiceId).then((res: ObjectKeys) => {
                if (res && res.data && res.data.data) {
                    const invoiceData = res.data.data;
                    setInvoice({
                        ...invoiceData,
                        services: invoiceData.original_data.services,
                        client: { data: invoiceData.original_data.client },
                        memorandums: invoiceData.memorandums?.data?.map((item: { id: string }) => item.id),
                    });
                    setOldInvoiceDate(invoiceData?.invoice_date);
                    const newUnits = res?.data.data.services
                        .map((item: ObjectKeys) => item.pivot.measurement_unit)
                        .filter((unit: any) => !units.includes(unit));

                    useUnitsStore.setState({ units: [...units, ...newUnits] });
                    if (invoiceData?.client?.data) {
                        const emails =
                            invoiceData.client.data.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
                        setClientEmails(
                            emails.length > 0
                                ? emails
                                : invoiceData.client.data.email
                                ? [invoiceData.client.data.email]
                                : [],
                        );
                    } else {
                        setClientEmails([]);
                    }
                    setLoading(false);
                }
            });
        }
    }, [invoiceId]);

    useEffect(() => {
        if (clickedClient) {
            const matchingClient = Array.isArray(clients.data)
                ? clients.data.find((client: ObjectKeys) => client.id === clickedClient)
                : null;
            matchingClient && handleSelectedClient(matchingClient);
        }
    }, [clients]);

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        if (client) {
            const emails = client.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
            setClientEmails(emails.length > 0 ? emails : client.email ? [client.email] : []);
        } else {
            setClientEmails([]);
        }
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            newInvoiceData.currency = client?.currency;
            if (client.is_foreign || client.type === 'civil') {
                newInvoiceData.sef_invoice_number = null;
            }
            return newInvoiceData;
        });
    };

    //Invoice Download
    const handleDownloadInvoice = async (): Promise<void> => {
        try {
            await handleUpdateInvoice().then(async (newInvoice: any) => {
                if (newInvoice?.error) {
                    setErrorMessage(newInvoice.error);
                    setOpenErrorModal(true);
                    setLoading(false);
                    return;
                }
                setLoading(true);
                communication
                    .downloadInvoice(
                        invoice.id,
                        token,
                        newInvoice.future_invoice_number || invoice.invoice_number || '',
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    )
                    .then(() => {
                        setLoading(false);
                        updateYears();
                        navigate('/advance-invoices');
                        setLoading(false);
                    })
                    .catch((error: any) => {
                        setLoading(false);
                        setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                        setOpenErrorModal(true);
                    });
            });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            if (invoice.services && invoice.services.length > 0 && !isEmpty(invoice)) {
                setChangeHappened(true);
            }
        }
    }, [invoice]);

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const handleIssueInvoice = async (): Promise<void> => {
        setLoading(true);
        const updatedInvoice = await handleUpdateInvoice();

        if (updatedInvoice.error) return;

        try {
            setLoading(true);
            communication
                .issueInvoice(invoice.id)
                .then((res: ObjectKeys) => {
                    if (res) {
                        setLoading(false);
                        navigate('/advance-invoices');
                    }
                })
                .catch((error: any) => {
                    console.error('Error issuing the invoice:', error);
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error('Error', error);
        }
    };

    return (
        <>
            {loading && <Loader />}
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoice}
                        t={t}
                        id={invoice.id}
                        success={() => {
                            setSendModal(false);
                            navigate('/advance-invoices');
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmails={clientEmails}
                        handleAddNewInvoice={handleUpdateInvoice}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        editInvoice={(updatedInvoice: ObjectKeys) => {
                            setInvoice(updatedInvoice);
                        }}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                    />
                </Sidebar>
            )}
            {pastYearModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setPastYearModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={update}
                        close={() => {
                            setPastYearModal(false);
                        }}
                        message={t('pages.editInvoice.pastYearModal').text}
                        confirmText={t('buttons.ok').text}
                        closeText={t('buttons.close').text}
                    />
                </Modal>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            setChangeHappened(false);
                            handleInvoiceDelete();
                        }}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'womensDay' && (
                        <PreviewInvoiceModalWomensDay
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                    {preferredTemplate === 'easter' && (
                        <PreviewInvoiceModalEaster
                            agency={invoice.original_data.agency}
                            invoice={invoice}
                            title="advance"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                        />
                    )}
                </Modal>
            )}

            <Container>
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleUpdateInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={async () => {
                            setPreviewModal(true);
                        }}
                        setSendModal={async () => {
                            const isValid = await isInvoiceValid();
                            isValid && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleUpdateInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                        issueInvoice={handleIssueInvoice}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleUpdateInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={async () => {
                            setPreviewModal(true);
                        }}
                        setSendModal={async () => {
                            const isValid = await isInvoiceValid();
                            isValid && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleUpdateInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                        issueInvoice={handleIssueInvoice}
                    />
                )}
                <Body className="edit-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={invoice?.type === 'national' ? 'domestic' : invoice?.type === 'foreign' ? 'foreign' : ''}
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {invoice && invoice.original_data && (
                        <Invoice>
                            {invoice?.type === 'national' ? (
                                <InvoiceEdit
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={invoice.original_data.agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobile={isMobile}
                                    setLoading={setLoading}
                                    type={'domestic'}
                                    newInvoice={false}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={invoice.original_data.agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            total_for_payment: sum,
                                        });
                                    }}
                                    isMobile={isMobile}
                                    setLoading={setLoading}
                                    type={'foreign'}
                                    clientChanged={clientChanged}
                                    handleChangeHappend={(change: boolean) => {
                                        setChangeHappened(change);
                                    }}
                                    newInvoice={false}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobile ? (
                        <MobileFooter
                            handleUpdateInvoice={() => {
                                handleUpdateInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/advance-invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={async () => {
                                setPreviewModal(true);
                            }}
                            setSendModal={async () => {
                                const isValid = await isInvoiceValid();
                                isValid && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                            handleViewInvoicePDF={handleViewInvoicePDF}
                            issueInvoice={handleIssueInvoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={() => {
                                handleUpdateInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/advance-invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={async () => {
                                setPreviewModal(true);
                            }}
                            setSendModal={async () => {
                                const isValid = await isInvoiceValid();
                                isValid && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                            handleViewInvoicePDF={handleViewInvoicePDF}
                            issueInvoice={handleIssueInvoice}
                        />
                    )}
                </Body>
            </Container>
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
        </>
    );
};
export default CopyEInvoice;

import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate, useBlocker } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { some, isEmpty } from 'lodash';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import ErrorModal from '../../../../components/errorModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../common/previewInvoiceModalPlainPenguin';
import PreviewInvoiceModalEaster from '../../common/previewInvoiceModalEaster';
import useUserStore from '../../../../store/user';
import { Invoice } from '../../common/style';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import WebFooter from '../../common/footer/webFooter';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import getAllProforma from '../getAllProforma';
import SendModal from '../sendModal';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import useYearsStore from '../../../../store/years';
import getAllClients from '../../../myBusiness/clients/getAllClients';
import { toast } from 'react-toastify';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../../myBusiness/clients/warningCard';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import PreviewInvoiceModalWomensDay from '../../common/previewInvoiceModalWomensDay';

const NewProforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const navigate = useNavigate();
    const token = getAccessToken();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const proformaType = pathname.includes('domestic') ? 'domestic' : 'foreign';

    const [previewModal, setPreviewModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);

    const [qrCode, setQrCode] = useState('');
    const [clickedClient, setClickedClient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const { isMobile } = useResponsive();

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const domesticBankAccounts =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .sort((a: ObjectKeys, b: ObjectKeys) => (b.default === 1 ? 1 : -1)) // Sort by default property
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});
    const [clients, setClients] = useState<ObjectKeys>([]);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const [proformaData, setProformaData] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        bank_account:
            proformaType === 'foreign'
                ? swiftIbanOptions[0]?.value?.account_number
                : domesticBankAccounts[0]?.value?.account_number,
        bank_account_id: proformaType === 'foreign' ? '' : domesticBankAccounts[0]?.value?.id,
        currency: proformaType === 'domestic' ? 'RSD' : 'EUR',
        paid_date: '',
        paid_value: false,
        rate: 1,
        seal_required: false,
        iban: null,
        swift: null,
        trading_place: '',
        type: proformaType,
        memorandums: [],
    });

    useEffect(() => {
        if (agency && proformaType && currentLang) {
            const domesticNote = agency.user.data.note_domestic;
            const foreignNote = agency.user.data.note_foreign;
            if (proformaType === 'domestic') {
                setProformaData({
                    ...proformaData,
                    comment: domesticNote ? domesticNote : t('pages.editInvoice.previewModal.due').text,
                });
            } else {
                setProformaData({
                    ...proformaData,
                    comment: foreignNote ? foreignNote : 'Payment deadline is 15 days',
                });
            }
        }
    }, [proformaType, currentLang]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setProformaData((prev: ObjectKeys) => {
                const newProformaData = { ...prev };
                newProformaData.client_id = newCreatedClient && newCreatedClient?.id;
                newProformaData.client.data = newCreatedClient;
                return newProformaData;
            });
        }
    }, [newCreatedClient]);

    useEffect(() => {
        setChangeHappened(false);
    }, []);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmails([]);
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, proformaType === 'domestic' ? 0 : 1);
                    setDeleteClientModal(false);
                    setLoading(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setProformaData((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
            })
            .catch((error: ObjectKeys) => {
                setLoading(false);
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
    };

    const handleProformaDelete = async (): Promise<void> => {
        const res = await communication.deleteProforma(proformaData.id);
        if (res.status === 200) {
            setDeleteInvoiceModal(false);
            navigate('/proforma');
        }
    };
    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getQuotationYears();
        useYearsStore.setState({ quotationYears: yearsData?.data?.years });
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        if (client) {
            const emails = client.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
            setClientEmails(emails.length > 0 ? emails : client.email ? [client.email] : []);
        } else {
            setClientEmails([]);
        }
        setProformaData((prev: ObjectKeys) => {
            const newProformaData = { ...prev };
            newProformaData.client_id = client?.id;
            newProformaData.client.data = client;
            return newProformaData;
        });
        agency &&
            proformaData &&
            proformaType !== 'foreign' &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                proformaData.value_in_rsd,
                proformaData.quotation_number ? proformaData.quotation_number : '',
                proformaData.bank_account ? proformaData.bank_account : domesticBankAccounts[0]?.value?.account_number,
                proformaData.currency ? proformaData.currency : 'RSD',
                true,
            );
    };

    useEffect(() => {
        const matchingClient = Array.isArray(clients.data)
            ? clients.data.find((client: ObjectKeys) => client.id === proformaData.client.data.id)
            : null;
        matchingClient && handleSelectedClient(matchingClient);
    }, [clients]);

    useEffect(() => {
        if ((previewModal || sendModal) && proformaType !== 'foreign' && proformaData.value_in_rsd) {
            handleGenerateQRCode(
                agency,
                proformaData.client.company_name,
                proformaData.value_in_rsd,
                proformaData.quotation_number ? proformaData.quotation_number : '',
                proformaData.bank_account ? proformaData.bank_account : domesticBankAccounts[0]?.value?.account_number,
                proformaData.currency ? proformaData.currency : 'RSD',
                true,
            );
        }
    }, [previewModal, sendModal]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData.name,
                clientData,
                amount,
                proformaNumber,
                bankAccount,
                currency,
                convert,
                proformaNumber ? `00${proformaNumber?.replace('/', '-')}` : '',
                '221',
            ).then((response: ObjectKeys | any) => {
                if (response.i) setQrCode(response.i);
                else setQrCode('');
            });
        } catch (e: any) {
            setQrCode('');
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const isProformaValid = (): boolean => {
        if (!proformaData.services || proformaData.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            return false;
        }

        if (
            some(
                proformaData.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            return false;
        }

        if (!proformaData.client_id || Object.keys(proformaData.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            return false;
        }

        if (proformaData.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            return false;
        }
        if (
            !validateIbanSwift(
                proformaData.iban,
                proformaData.swift,
                proformaData.bank_account_id,
                returnBankCodeFromId(proformaData.bank_id) === '000' || proformaType !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            return false;
        }

        return true;
    };

    const handleAddNewProforma = async (): Promise<any> => {
        if (!isProformaValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const newProformaData = { ...proformaData };

            newProformaData.services = newProformaData.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity.replace(',', '.'),
                price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                discount: service.pivot.discount,
                description: service.pivot.description,
            }));
            newProformaData.trading_date = moment(newProformaData.trading_date).format('YYYY-MM-DD').toString();
            newProformaData.rate = Number(newProformaData.rate);
            newProformaData.paid_date = moment(
                newProformaData.paid_date ? newProformaData.paid_date : new Date('1970-01-01'),
            )
                .format('YYYY-MM-DD')
                .toString();
            newProformaData.quotation_date = moment(newProformaData.quotation_date).format('YYYY-MM-DD').toString();
            newProformaData.custom_number = false;
            newProformaData.is_custom = false;
            newProformaData.custom_text = false;
            newProformaData.invoice_per_owner = true;
            newProformaData.auto_increment = true;
            newProformaData.cancel_number = false;
            newProformaData.cancelled = false;
            newProformaData.currency = proformaData.currency;
            newProformaData.status = 'open';
            newProformaData.user_id = agency?.user_id;
            newProformaData.trading_place = proformaData?.trading_place;
            newProformaData.client_id = proformaData?.client?.data?.id;
            newProformaData.is_prepaid = false;
            delete newProformaData.total_for_payment_in_rsd, newProformaData.total_for_payment;
            if (proformaType === 'domestic') {
                newProformaData.bank_account = proformaData.bank_account;
            } else {
                newProformaData.swift = proformaData?.swift;
                newProformaData.iban = proformaData?.iban;
            }

            const res = await communication.newProforma(newProformaData, proformaType);
            if (res) {
                updateYears();
                setLoading(false);
                getAllProforma(setProformaData);
                navigate('/proforma');
                return {
                    id: res?.data?.data?.id,
                    number: res?.data?.data?.quotation_number,
                    agency: res?.data?.data?.agency?.data.full_name,
                    client: res?.data?.data?.client.data.company_name,
                    amount: res?.data?.data?.value,
                    account: res?.data?.data?.bank_account,
                    currency: res?.data?.data?.currency,
                    foreign: res?.data?.data?.type === 'foreign' ? true : false,
                };
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.errors ? e.response.data.errors.client_id[0] : e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    //Proforma Download
    const handleDownloadProforma = async (): Promise<void> => {
        const newCreatedProforma = await handleAddNewProforma();
        if (!newCreatedProforma) return;
        setLoading(true);
        if (newCreatedProforma.foreign) {
            communication
                .downloadProforma(
                    newCreatedProforma.id,
                    token,
                    newCreatedProforma.number,
                    proformaData.currency !== 'RSD',
                )
                .then(() => {
                    setLoading(false);
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                    setOpenErrorModal(true);
                    setLoading(false);
                });
        } else {
            generateQrCode(
                agency.name,
                proformaData.client ? proformaData.client.data.company_name : '',
                proformaData.value_in_rsd ? proformaData.value_in_rsd : '0',
                newCreatedProforma.number,
                newCreatedProforma.account,
                proformaData.currency,
                true,
                newCreatedProforma.number ? `00${newCreatedProforma.number?.replace('/', '-')}` : null,
                '221',
            )
                .then(async (res: ObjectKeys | any) => {
                    if (res) {
                        communication
                            .downloadProforma(
                                newCreatedProforma.id,
                                token,
                                newCreatedProforma.number,
                                proformaData.currency !== 'RSD',
                                res?.i ? res.i : '""',
                            )
                            .then(() => {
                                setLoading(false);
                            })
                            .catch((error: ObjectKeys) => {
                                setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                setOpenErrorModal(true);
                                setLoading(false);
                            });
                    }
                })
                .catch(() => {
                    communication
                        .downloadProforma(
                            newCreatedProforma.id,
                            token,
                            newCreatedProforma.number,
                            proformaData.currency !== 'RSD',
                        )
                        .then(() => {
                            setLoading(false);
                        })
                        .catch((error: ObjectKeys) => {
                            setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                            setOpenErrorModal(true);
                            setLoading(false);
                        });
                });
        }
    };

    //Proforma Print
    const handleViewInvoicePDF = async (): Promise<void> => {
        const newCreatedProforma = await handleAddNewProforma();
        if (!newCreatedProforma) return;
        setLoading(true);
        if (newCreatedProforma.foreign) {
            communication
                .printProforma(newCreatedProforma.id, token, newCreatedProforma.number, proformaData.currency !== 'RSD')
                .then((resp: ObjectKeys) => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(resp.data);
                    const printWindow = window.open(url, '_blank');
                    if (printWindow) {
                        printWindow.onload = () => {
                            printWindow.focus();
                            printWindow.print();
                        };
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                    setOpenErrorModal(true);
                    setLoading(false);
                });
        } else {
            generateQrCode(
                agency.name,
                proformaData.client ? proformaData.client.data.company_name : '',
                proformaData.value_in_rsd ? proformaData.value_in_rsd : '0',
                newCreatedProforma.number,
                newCreatedProforma.account,
                proformaData.currency,
                true,
                newCreatedProforma.number ? `00${newCreatedProforma.number?.replace('/', '-')}` : null,
                '221',
            )
                .then(async (res: ObjectKeys | any) => {
                    if (res) {
                        communication
                            .printProforma(
                                newCreatedProforma.id,
                                token,
                                newCreatedProforma.number,
                                proformaData.currency !== 'RSD',
                                res?.i ? res.i : '""',
                            )
                            .then((resp: ObjectKeys) => {
                                setLoading(false);
                                const url = window.URL.createObjectURL(resp.data);
                                const printWindow = window.open(url, '_blank');
                                if (printWindow) {
                                    printWindow.onload = () => {
                                        printWindow.focus();
                                        printWindow.print();
                                    };
                                }
                            })
                            .catch((error: ObjectKeys) => {
                                setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                                setOpenErrorModal(true);
                                setLoading(false);
                            });
                    }
                })
                .catch(() => {
                    communication
                        .printProforma(
                            newCreatedProforma.id,
                            token,
                            newCreatedProforma.number,
                            proformaData.currency !== 'RSD',
                        )
                        .then((resp: ObjectKeys) => {
                            setLoading(false);
                            const url = window.URL.createObjectURL(resp.data);
                            const printWindow = window.open(url, '_blank');
                            if (printWindow) {
                                printWindow.onload = () => {
                                    printWindow.focus();
                                    printWindow.print();
                                };
                            }
                        })
                        .catch((error: ObjectKeys) => {
                            setErrorMessage(error?.message || t('pages.invoices.cancelModal.failed').text);
                            setOpenErrorModal(true);
                            setLoading(false);
                        });
                });
        }
    };

    const updateCheckedAccounts = async (): Promise<void> => {
        const form = {
            foreign_accounts_check: true,
        };
        const res = await communication.updateUser(form, agency.user.data.id);
        if (res.status === 200) {
            useAgencyStore.setState((prevState) => ({
                agency: {
                    ...prevState.agency,
                    user: {
                        ...prevState.agency.user,
                        data: res.data.data,
                    },
                },
            }));
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            {loading && <Loader />}
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={proformaType === 'domestic' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={proformaType === 'domestic' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleProformaDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.newProforma.deleteProforma').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        proforma={proformaData}
                        t={t}
                        success={() => {
                            setSuccess(true);
                            navigate('/proforma');
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmails={clientEmails}
                        setProforma={setProformaData}
                        handleAddNewProforma={handleAddNewProforma}
                        qrCode={qrCode}
                        modalVisible={sendModal}
                        editInvoice={(updatedProforma: ObjectKeys) => {
                            setProformaData(updatedProforma);
                        }}
                    />
                </Sidebar>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {/* // {preferredTemplate === 'Artistic' && (
                    //     <PreviewInvoiceModalArtistic
                    //         agency={agency}
                    //         invoice={proformaData}
                    //         title="proforma"
                    //         type={proformaType}
                    //         note={proformaData.comment}
                    //         qrCode={qrCode}
                    //     />
                    // )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'womensDay' && (
                        <PreviewInvoiceModalWomensDay
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'easter' && (
                        <PreviewInvoiceModalEaster
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaType}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <PageWrapper className="new-proforma page">
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        newInvoice={true}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        newInvoice={true}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                )}
                <InvoiceClients
                    t={t}
                    setClients={setClients}
                    clients={clients}
                    setAddClientModal={setAddClientModal}
                    agency={agency}
                    handleSelectedClient={handleSelectedClient}
                    addClientModal={addClientModal}
                    deleteClientModal={deleteClientModal}
                    editClientModal={editClientModal}
                    setClickedClient={setClickedClient}
                    setEditClientModal={setEditClientModal}
                    setDeleteClientModal={setDeleteClientModal}
                    invoice={proformaData}
                    type={pathname.includes('foreign') ? 'foreign' : pathname.includes('domestic') ? 'domestic' : ''}
                    loading={(isLoading: boolean) => setLoading(isLoading)}
                />
                {agency && proformaData && (
                    <Invoice>
                        {pathname.includes('domestic') ? (
                            <InvoiceEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={proformaType}
                                setLoading={setLoading}
                                handleQrCode={(qr: string) => setQrCode(qr)}
                            />
                        ) : (
                            <InvoiceForeignEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        total_for_payment: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={proformaType}
                                setLoading={setLoading}
                                handleChangeHappend={(change: boolean) => {
                                    setChangeHappened(change);
                                }}
                            />
                        )}
                    </Invoice>
                )}
                {isMobile ? (
                    <MobileFooter
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        invoice={proformaData}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                ) : (
                    <WebFooter
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        invoice={proformaData}
                        handleViewInvoicePDF={handleViewInvoicePDF}
                    />
                )}
            </PageWrapper>
        </>
    );
};
export default NewProforma;
const PageWrapper = styled.div``;

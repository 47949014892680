import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useLanguageStore from '../../store/language';
import useChangeModalStore from '../../store/changeModal';
import useTranslations from '../../hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../hooks/useTheme';

interface Props {
    close: Function;
    saveChanges: Function;
    message: string;
    active?: boolean;
    className?: string;
    children?: string | any;
    confirmText?: boolean;
    closeText?: boolean;
}

const ChangeModal: FunctionComponent<Props> = ({ close, saveChanges, message, children, confirmText, closeText }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const isDarkTheme = useTheme();

    return (
        <>
            <TextContainer>
                <h3>{message}</h3>
            </TextContainer>
            {children && <>{children}</>}
            <ButtonContainer isDarkTheme={isDarkTheme}>
                <Button
                    textTransformNone
                    variant={ButtonVariant.solid}
                    color={'var(--purple)'}
                    onClick={() => {
                        useChangeModalStore.setState({ changeModalVisible: false });
                        saveChanges();
                    }}
                    size={'50%'}
                >
                    {confirmText ? confirmText : t('input.yes').text}
                </Button>

                <Button
                    textTransformNone
                    variant={ButtonVariant.outlined}
                    color={'var(--white)'}
                    onClick={() => {
                        useChangeModalStore.setState({ changeModalVisible: false });
                        message === t('pages.editInvoice.quitQuestion').text && navigate(-1);
                        close();
                    }}
                    size={'50%'}
                >
                    {closeText ? closeText : t('input.no').text}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default ChangeModal;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div<DarkThemeProps>`
    display: flex;
    width: 100%;
    button {
        border: none;
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : '#fff')};
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-right: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#fff')};
    }
`;

import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from '../../global/colors';
import Button, { ButtonVariant } from '../button';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useTheme from '../../hooks/useTheme';

type Props = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const ErrorModal: FunctionComponent<Props> = ({ setOpenModal }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const isDarkTheme = useTheme();
    return (
        <ErrorModalWrapper isDarkTheme={isDarkTheme}>
            <ModalText>
                <h4>{t('modal.error.title').text}</h4>
                <p>{t('warnings.qrCodeTooManyTry').text}</p>
            </ModalText>
            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'100%'}
                onClick={() => {
                    setOpenModal(false);
                }}
            >
                {t('buttons.ok').text}
            </Button>
        </ErrorModalWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const ErrorModalWrapper = styled.div<DarkThemeProps>`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 35px;
    }
    p {
        line-height: 30px;
    }
`;

export default ErrorModal;

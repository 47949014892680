import { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAllUsersStore from '../../../store/admin/allUsers';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Modal from '../../../components/modal';
import Filters from './filters';
import DeleteModal from './deleteModal';
import ErrorModal from '../../../components/errorModal';
import SearchCustomComponent from '../../../components/customSelect';
import { getAccessToken } from '../../../functions/auth';
import { generateQrCode } from '../../../communication/qr';
import useUserStore from '../../../store/user';
import { formatClientParams } from '../../../functions/formatClientParams';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';
import Checkbox from '../../../components/checkbox';
import colors from '../../../global/colors';
import { faTrashCan, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import HandleInvoicesModal from '../../../pages/pausal/common/handleInvoicesModal';

const Proforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { userBOid } = useUserStore();
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { allUsers } = useAllUsersStore();
    const token = getAccessToken();

    const [proforma, setProforma] = useState<ObjectKeys>({});
    const [errorMessage, setErrorMessage] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [display, setDisplay] = useState('list');
    const [clickedProforma, setClickedProforma] = useState('');
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');

    const [agencyProfile, setAgencyProfile] = useState<ObjectKeys>({});

    const [selectedProformaArray, setSelectedProformaArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isProformaModalOpen, setIsProformaModalOpen] = useState<boolean>(false);
    const [proformaModalType, setProformaModalType] = useState<string>('');

    useBackOfficePasswordProtect();

    const [params, setParams] = useState({
        page: 1,
        limit: '10',
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });
    const formattedParams = useMemo(() => formatClientParams(params.search), [params]);
    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const getProforma = (): void => {
        setLoaderVisible(true);
        communication
            .getProformaByUserId({
                ...params,
                search: `user_id:${selectedUserId};` + formattedParams,
                searchFields: 'user_id:=;' + formatParams(params.searchFields),
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setProforma(res?.data);
                    handleSelectAllProformas(false);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setProforma([]);
                }
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        if (selectedUserId) {
            getProforma();
        }
    }, [selectedUserId, formattedParams, params.page, params.limit]);

    useEffect(() => {
        if (selectedUserId) {
            communication.getUsersById(selectedUserId).then((user: ObjectKeys) => {
                const agencyData = user.data.data.agency.data[0];
                setAgencyProfile({
                    ...agencyData,
                    name: agencyData.full_name,
                });
            });
        }
    }, [selectedUserId]);

    // const handlePageClick = (e: any): void => {
    //     setPage(e.selected + 1);
    // };

    //Proforma Change
    const handleChange = (proformaId: string): void => {
        navigate(`/admin/proforma/edit/${proformaId}/${selectedUserId}`);
    };

    //Proforma Delete Modal
    const handleDeleteModal = (proformaId: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedProforma={clickedProforma}
                closeGlobalModal={closeGlobalModal}
                setLoaderVisible={setLoaderVisible}
                onProformaDeleted={() => {
                    getProforma();
                }}
                proformaId={proformaId}
            />,
        );
    };

    const handleClickedProforma = (proformaId: string): void => {
        setClickedProforma(proformaId);
    };

    // Create invoice from proforma
    const handleInvoice = (proformaData: ObjectKeys): void => {
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: any, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';

        if (proformaData.type === 'national') {
            communication
                .domesticProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        } else if (proformaData.type === 'foreign') {
            communication
                .foreignProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        }
    };
    // Create advance from proforma
    const handleAdvance = (proformaData: ObjectKeys): void => {
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: any, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';

        if (proformaData.type === 'national') {
            communication
                .domesticProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        } else if (proformaData.type === 'foreign') {
            communication
                .foreignProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        }
    };
    const handleDownloadProforma = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        if (invoice.type !== 'foreign') {
            generateQrCode(
                agency.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
            )
                .then((res: ObjectKeys | any) => {
                    if (res.i) {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            true,
                            res.i,
                        );
                    } else {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            true,
                            undefined,
                        );
                    }
                })
                .catch((error: ObjectKeys) => {
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        true,
                        undefined,
                    );
                    console.error(error);
                });
        } else {
            communication.downloadInvoice(
                invoiceId,
                token,
                invoice.invoice_number,
                invoice.currency !== 'RSD',
                true,
                undefined,
            );
        }
    };

    const handleDeleteSelectedProforma = (): void => {
        setIsProformaModalOpen(true);
        getSelectedProformaNames();
        setProformaModalType('delete');
    };

    const handleDownloadSelectedProforma = (): void => {
        getSelectedProformaNames();
        setProformaModalType('download');
    };

    const handleSelectedProformaPayment = (): void => {
        setIsProformaModalOpen(true);
        getSelectedProformaNames();
        setProformaModalType('payment');
    };

    const getSelectedProformaNames = (): string[] => {
        const proformaNamesArray: string[] = [];
        selectedProformaArray.map((separateItem) => {
            const foundObject = proforma?.data?.find((obj: any) => obj.id === separateItem);

            proformaNamesArray.push(`${foundObject.quotation_number}`);
        });

        return proformaNamesArray;
    };

    const handleSelectAllProformas = (isChecked: boolean) => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newProformaIds = proforma?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedProformaArray(newProformaIds);
        } else {
            setSelectedProformaArray([]);
        }
    };
    const handleModalSubmission = (): void => {
        if (proformaModalType === 'delete') {
            setLoaderVisible(true);
            setIsProformaModalOpen(false);
            communication
                .deleteSelectedProformas('delete', selectedProformaArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        handleSelectAllProformas(false);
                        getProforma();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                    toast.error(error?.response?.data?.message);
                });
        } else if (proformaModalType === 'payment') {
            setLoaderVisible(true);
            setIsProformaModalOpen(false);
            communication
                .chargeSelectedInvoices('charge', selectedProformaArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        getProforma();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                });
        }
    };
    const handleSelectedCard = (id: string): void => {
        const isProformaInArray = selectedProformaArray.find((selectedInvoice) => selectedInvoice === id);
        if (isProformaInArray) {
            const filteredProformaList = selectedProformaArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedProformaArray(filteredProformaList);
        } else {
            setSelectedProformaArray((prevState) => [...prevState, id]);
        }
    };

    return (
        <>
            {openErrorModal ? (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            ) : (
                <></>
            )}

            {isProformaModalOpen && (
                <Modal
                    modalVisible={isProformaModalOpen}
                    closeModal={() => {
                        setIsProformaModalOpen(false);
                    }}
                >
                    <HandleInvoicesModal
                        t={t}
                        closeGlobalModal={() => {
                            setIsProformaModalOpen(false);
                        }}
                        selectedInvoicesInformationArray={getSelectedProformaNames()}
                        modalType={proformaModalType}
                        invoiceType="proforma"
                        isModalConfirmed={handleModalSubmission}
                    />
                </Modal>
            )}

            {loaderVisible && <Loader />}
            <div className="proforma page">
                <Header>
                    <h1>{t('pages.proforma.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                </SelectWrapper>
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                {proforma?.data?.length > 0 && (
                    <SelectAllSection>
                        <div className="selectCheckbox">
                            <Checkbox
                                onChange={(value: any) => {
                                    handleSelectAllProformas(value);
                                }}
                                label={t('pages.agency.extra_services.select_all').text}
                                defaultChecked={isAllChecked}
                            />
                        </div>
                        {selectedProformaArray.length > 0 && (
                            <div className="userControlSection">
                                <div className="selectionDiv" onClick={handleDeleteSelectedProforma}>
                                    <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                </div>
                                {/* <div className="selectionDiv" onClick={handleDownloadSelectedProforma}>
                                <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                            </div> */}
                            </div>
                        )}
                    </SelectAllSection>
                )}
                <InvoicesList className={`display-${display}`}>
                    {proforma?.data?.length > 0 ? (
                        proforma?.data?.map((pForma: ObjectKeys) => {
                            return (
                                <div key={pForma.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.Proforma}
                                        cardData={pForma}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(pForma.id)}
                                        handleCopy={() => {
                                            return <></>;
                                        }}
                                        handleDelete={() => handleDeleteModal(pForma.id)}
                                        handleDownload={() => {
                                            handleDownloadProforma(
                                                pForma?.id,
                                                agencyProfile,
                                                pForma?.client?.data,
                                                pForma.value_in_rsd,
                                                pForma.quotation_number,
                                                pForma.bank_account,
                                                pForma.currency,
                                                true,
                                                pForma,
                                            );
                                        }}
                                        handleInvoice={() => handleInvoice(pForma)}
                                        handleAdvance={() => handleAdvance(pForma)}
                                        handleClick={() => handleClickedProforma(pForma.id)}
                                        eInvoice={pForma?.is_einvoice}
                                        checkedInvoice={handleSelectedCard}
                                        cardIdArray={selectedProformaArray}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.proforma.noItems.description').text} />
                    )}
                </InvoicesList>
                {proforma?.data?.length > 0 && (
                    <Pagination
                        pageCount={proforma.meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${
                            proforma.meta?.pagination?.current_page === proforma.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${proforma.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={proforma.meta?.pagination?.current_page - 1}
                        currentPage={proforma.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default Proforma;

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
        }
    }
`;

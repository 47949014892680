import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import { ObjectKeys } from '../../../types/objectKeys';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Loader from '../../../components/loader';
import Checkbox from '../../../components/checkbox';
import communication from '../../../communication';
import { formatTwoDecimals } from '../../../functions/format';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import useServicesStore from '../../../store/services';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import Input, { InputComponentType } from '../../../components/input';
import useResponsive from '../../../hooks/responsive/useResponsive';
import ServiceCard from './serviceCard';
import ConsultationsCard from './consultationsCard';
import { toast } from 'react-toastify';
import Button, { ButtonVariant } from '../../../components/button';
import InfoModal from '../../../components/infoModal';
import Modal from '../../../components/modal';

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

interface StepOneProps {
    nextStep: Function;
    finallCart: Function;
    mainCart: ObjectKeys;
}

const StepOne: React.FC<StepOneProps> = ({ nextStep, finallCart, mainCart }) => {
    const { currentLang } = useLanguageStore();
    const lang = getCurrentLanguage();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const [searchParams, setSearchParams] = useSearchParams();

    const { isTabletAndDown } = useResponsive();

    const [loader, setLoader] = useState<boolean>(false);
    const [services, setServices] = useState<Service[]>([]);

    const [totalAmount, setTotalAmount] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');
    const [expandedServiceId, setExpandedServiceId] = useState('');

    const [validationError, setValidationError] = useState<boolean>(false);

    const [filteredServices, setFilteredServices] = useState<any[]>([]);

    const [renderKey, setRenderKey] = useState(0);

    const [servicePackageValidationErrorId, setServicePackageValidationErrorId] = useState<string>('');
    const [consultationPackageValidationErrorId, setConsultationPackageValidationErrorId] = useState<string>('');

    const [chosenPackage_id, setChosenPackage_id] = useState<string>('');
    const [chosenConsultaionPackage_id, setChosenConsultaionPackage_id] = useState<string>('');

    const [service_pre_cart_items, setServicePreCartItems] = useState<any[]>([]);
    const [consultation_pre_cart_items, setConsultationPreCartItems] = useState<any[]>([]);

    const [plainServicesPreCart, setPlainServicesPreCart] = useState<any[]>([]);

    const guestCart = localStorage.getItem('guestCart');
    const [resetSignal, setResetSignal] = useState<number>(0);

    const [emptyCartModal, setEmptyCardModal] = useState<boolean>(false);

    const [cart, setCart] = useState<ObjectKeys>(
        guestCart
            ? JSON.parse(guestCart)
            : {
                  status: '',
                  total_price: 0,
                  type: 'service',
                  email: '',
                  pib: '',
                  first_name: '',
                  last_name: '',
                  language: lang,
                  note: '',
                  items: [],
              },
    );

    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

    const toggleSelectAll = (): void => {
        setSelectAllChecked(!selectAllChecked);

        if (selectAllChecked) {
            setPlainServicesPreCart([]);
        } else {
            setPlainServicesPreCart(filteredServices.map((service) => ({ ...service, quantity: 1 })));
        }
    };

    const resetCart = (): void => {
        setCart({
            status: '',
            total_price: 0,
            type: 'service',
            email: '',
            pib: '',
            first_name: '',
            last_name: '',
            language: lang,
            note: '',
            items: [],
        });
    };

    const getAllServices = (): void => {
        setLoader(true);
        const params = {
            page: 1,
            limit: 1000,
            search: 'is_consultation:0',
            searchFields: 'is_consultation:=',
            searchJoin: 'and',
            orderBy: `name_${lang}`,
            sortedBy: 'asc',
        };
        communication
            .getAllGuestServices(params)
            .then((response: ObjectKeys) => {
                useServicesStore.setState(() => ({
                    servicesData: response.data.data,
                }));

                const servicesWithQuantitiesAndFree = response.data.data.map((service: any) => {
                    return {
                        ...service,
                        quantity: 0, // Set the initial quantity for each service
                        price: service.price, // Set price to 0 if it's a free service
                        type: 'service',
                    };
                });

                setServices(servicesWithQuantitiesAndFree);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getQuantityForService = (row: ObjectKeys): number => {
        const rowId = row.id;
        const preCartItem = plainServicesPreCart.find((item) => item.id === rowId);
        if (preCartItem) return preCartItem.quantity;
        else return 0;
    };

    const getCheckedForService = (row: ObjectKeys): boolean => {
        const rowId = row.id;
        const cartItem = cart.items.find((item: ObjectKeys) => item.id === rowId);
        return cartItem ? true : false;
    };

    const handleCheckbox = (service: ObjectKeys): void => {
        setSelectAllChecked(false);
        const serviceId = service.id;
        const updatedPreCart = [...plainServicesPreCart];
        const foundIndex = updatedPreCart.findIndex((item) => item.id === serviceId);

        if (foundIndex !== -1) {
            updatedPreCart.splice(foundIndex, 1); // Remove if already selected
        } else {
            updatedPreCart.push({ ...service, quantity: 1 }); // Add with quantity = 1
        }

        setPlainServicesPreCart(updatedPreCart);
    };

    const handleCounterIncrease = (service: ObjectKeys): void => {
        const serviceId = service.id;
        const existingItemIndex = plainServicesPreCart.findIndex((item: ObjectKeys) => item.id === serviceId);
        const updatedPreCart = [...plainServicesPreCart];

        if (existingItemIndex !== -1) {
            const existingItem = updatedPreCart[existingItemIndex];
            if (existingItem.quantity < 20) {
                updatedPreCart[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity + 1,
                };
            }
        } else {
            updatedPreCart.push({ ...service, quantity: 1 });
        }

        setPlainServicesPreCart(updatedPreCart);
    };

    const handleCounterDecrease = (service: ObjectKeys): void => {
        const serviceId = service.id;
        const existingItemIndex = plainServicesPreCart.findIndex((item: ObjectKeys) => item.id === serviceId);
        if (existingItemIndex !== -1) {
            const updatedPreCart = [...plainServicesPreCart];
            const existingItem = updatedPreCart[existingItemIndex];

            if (existingItem.quantity > 1) {
                updatedPreCart[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity - 1,
                };
            } else {
                updatedPreCart.splice(existingItemIndex, 1);
            }
            setPlainServicesPreCart(updatedPreCart);
        }
    };

    const movePreCartToCart = (): void => {
        if (selectAllChecked) {
            setSelectAllChecked(false);
        }
        setCart({ ...cart, items: [...cart.items, ...plainServicesPreCart] });
        resetPreCarts();
        setRenderKey((prevRenderKey) => prevRenderKey + 1);

        toast.success(
            <div>
                {t('pages.agency.subscription.serviceAddedToCart').text}
                <button
                    onClick={() =>
                        navigate('/guest/2', {
                            state: { fromTab: 'packages', tabIndex: 0 }, // Passing state
                        })
                    }
                    style={{
                        marginLeft: '10px',
                        background: 'transparent',
                        border: 'none',
                        color: 'var(--purple)',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    }}
                >
                    {t('pages.agency.subscription.goToCart').text}
                </button>
            </div>,
            { autoClose: 5000 }, // Keeps the toast visible for 5s
        );
    };

    const handleServiceClick = (serviceId: string): void => {
        setExpandedServiceId(serviceId === expandedServiceId ? '' : serviceId);
    };

    // useEffect(() => {
    //     if (mainCart && JSON.stringify(mainCart) !== JSON.stringify(cart)) {
    //         setCart(mainCart);
    //     }
    // }, [mainCart]);

    useEffect(() => {
        finallCart(cart);
        const newTotalAmount = cart.items.reduce(
            (total: any, service: ObjectKeys) => total + service.price * service.quantity,
            0,
        );
        setTotalAmount(newTotalAmount);
    }, [cart]);

    useEffect(() => {
        setCart((prevCart) => {
            const updatedCart = { ...prevCart };
            updatedCart.total_price = totalAmount;
            return updatedCart;
        });
    }, [totalAmount]);

    useEffect(() => {
        getAllServices();
    }, []);

    useEffect(() => {
        if (services.length > 0) {
            const updatedServices = services.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );
            setFilteredServices(updatedServices);
        }
    }, [services]);

    useEffect(() => {
        if (services.length > 0) {
            const updatedServices = services.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );
            setFilteredServices(updatedServices);
        }
        setCart((prevCart) => {
            return {
                ...prevCart,
                language: lang,
            };
        });
    }, [searchQuery, lang]);

    // *************** Service packages section ***************

    const fetchServicePackages = (): void => {
        setLoader(true);

        const params = {
            page: 1,
            limit: 100,
            search: 'type:service;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                const originalData = response?.data?.data || [];
                setServicePackagesArray(originalData);
                // Determine free services for the user

                setLoader(false);
            })
            .catch((error: any) => {
                setServicePackagesArray([]);
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const [tabIndex, setTabIndex] = useState<number>(0);
    const tabParam = searchParams.get('tab');
    const tabs = [
        t('pages.agency.extra_services.packageServices').text,
        t('pages.agency.extra_services.consultationsPackages').text,
        t('pages.agency.extra_services.separateServices').text,
    ];

    const [servicePackagesArray, setServicePackagesArray] = useState<string[]>([]);

    // *************** Consultation Service packages section ***************

    const [consultationPackagesArray, setConsultationPackagesArray] = useState<string[]>([]);

    const fetchConsultationPackages = (): void => {
        setLoader(true);

        const params = {
            page: 1,
            limit: 100,
            search: 'type:consultation;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                const originalData = response?.data?.data || [];
                setConsultationPackagesArray(originalData);
                // Determine free services for the user

                setLoader(false);
            })
            .catch((error: any) => {
                setConsultationPackagesArray([]);
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const resetIds = (): void => {
        setServicePackageValidationErrorId('');
        setConsultationPackageValidationErrorId('');
        setChosenPackage_id('');
        setChosenConsultaionPackage_id('');
    };

    const updateCartAdd = (chosenServices: ObjectKeys[], chosenPackageId: string): void => {
        setChosenPackage_id(chosenPackageId);

        const updated_cart = (() => {
            const existingItems = Array.isArray(cart.items) ? cart.items : [];
            const updatedItems = [...existingItems];

            chosenServices.forEach((newService) => {
                const existingItem = updatedItems.find((item) => item.id === newService.id);
                if (existingItem) {
                    existingItem.quantity += newService.quantity;
                } else {
                    updatedItems.push(newService);
                }
            });

            return {
                ...cart,
                items: updatedItems,
            };
        })();

        setCart(updated_cart);
    };

    const resetAllRadioButtons = (): void => {
        setResetSignal(Date.now()); // triggers update in all children
    };

    const resetPreCarts = (): void => {
        setServicePreCartItems([]);
        setConsultationPreCartItems([]);
        setPlainServicesPreCart([]);
    };

    useEffect(() => {
        resetIds();
        resetPreCarts();
        setValidationError(false);
        if (tabIndex === 0) {
            fetchServicePackages();
        } else if (tabIndex === 1) {
            fetchConsultationPackages();
        } else if (tabIndex === 2) {
            getAllServices();
        }
    }, [tabIndex]);

    useEffect(() => {
        if (tabParam === 'packages') {
            setTabIndex(0);
        } else if (tabParam === 'consultations') {
            setTabIndex(1);
        } else if (tabParam === 'allPackages') {
            setTabIndex(2);
        }
    }, [tabParam]);

    useEffect(() => {
        if (state && state.tabIndex) {
            setTabIndex(state.tabIndex);
        }
    }, [state]);

    return (
        <>
            {loader && <Loader />}
            {emptyCartModal && (
                <Modal modalVisible={emptyCartModal} closeModal={() => setEmptyCardModal(false)}>
                    <InfoModal
                        setOpenModal={setEmptyCardModal}
                        message={t('pages.agency.subscription.emptyCart').text}
                        buttonText={t('buttons.ok').text}
                    />
                </Modal>
            )}
            <Content>
                <ChooseFeature>
                    <div className="notes">
                        <div className="ad">
                            <p>{t('pages.agency.extra_services.packageAd').text}</p>
                        </div>
                        <div className="search-services">
                            {/* <div className="title-tooltip-wrapper">
                                <p className="chooseService">{t('pages.agency.extra_services.choose_service').text}</p>
                                <div className="tooltip-wrapper">
                                    <Tooltip
                                        text={t('pages.agency.extra_services.tooltip_text').text}
                                        position={!isTabletAndDown ? 'right' : 'bottom'}
                                        label={''}
                                    />
                                </div>
                            </div> */}
                            <div className="tabs">
                                {tabs.map((tab, index) => (
                                    <span
                                        className={`serviceHeader ${
                                            tabIndex === index && 'itemSelected'
                                        } smallerPadding`}
                                        key={index}
                                        onClick={() => {
                                            setTabIndex(index);
                                            if (index === 0) {
                                                setSearchParams({ tab: 'packages' });
                                            } else if (index === 1) {
                                                setSearchParams({ tab: 'consultations' });
                                            } else if (index === 2) {
                                                setSearchParams({ tab: 'allPackages' });
                                            }
                                        }}
                                    >
                                        <p>{tab}</p>
                                    </span>
                                ))}
                            </div>
                            {tabIndex === 2 && (
                                <div className="search-input">
                                    <FontAwesomeIcon icon={faSearch} />
                                    <Input
                                        type={InputComponentType.Text}
                                        onChange={(value: string) => {
                                            setSearchQuery(value);
                                        }}
                                        value={searchQuery}
                                        maxLength={50}
                                        placeholder={t('pages.agency.extra_services.search').text}
                                    />
                                </div>
                            )}
                        </div>
                        {tabIndex === 0 && (
                            <MainServicesWrapper>
                                <h1 className="header">{t('pages.agency.extra_services.packageServicesTitle').text}</h1>
                                <div className="cardWrapper">
                                    {servicePackagesArray.map((separatePackage: any, index: number) => (
                                        <ServiceCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys[], chosenPackageId: string) => {
                                                setServicePreCartItems((prev) => ({
                                                    ...prev,
                                                    [chosenPackageId]: chosenServices, // Add or replace services for this package
                                                }));
                                            }}
                                            removePackage={(chosenServices: ObjectKeys[], chosenPackageId: any) => {
                                                setServicePreCartItems((prev) => {
                                                    const updatedItems = { ...prev };
                                                    delete updatedItems[chosenPackageId]; // Remove services for this package
                                                    return updatedItems;
                                                });
                                            }}
                                            nextStep={(package_id: string, selectedOption: string) => {
                                                if (selectedOption === '') {
                                                    setServicePackageValidationErrorId(package_id);
                                                    return;
                                                }
                                                const itemNames = Object.keys(service_pre_cart_items);

                                                const filteredId = itemNames.find(
                                                    (item: string) => item === package_id,
                                                );
                                                resetPreCarts();
                                                resetAllRadioButtons();
                                                if (filteredId) {
                                                    const new_services = (
                                                        service_pre_cart_items as Record<string, any>
                                                    )[filteredId];
                                                    if (services) {
                                                        updateCartAdd(new_services, filteredId);
                                                    }

                                                    toast.success(
                                                        <div>
                                                            {t('pages.agency.subscription.serviceAddedToCart').text}
                                                            <button
                                                                onClick={() => {
                                                                    resetPreCarts();
                                                                    navigate('/guest/2', {
                                                                        state: { fromTab: 'packages', tabIndex: 0 },
                                                                    });
                                                                }}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color: 'var(--purple)',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {t('pages.agency.subscription.goToCart').text}
                                                            </button>
                                                        </div>,
                                                        { autoClose: 5000 },
                                                    );
                                                } else {
                                                    setServicePackageValidationErrorId(package_id);
                                                }
                                            }}
                                            emptyCartError={servicePackageValidationErrorId === separatePackage.id}
                                            resetSelectionSignal={resetSignal}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 1 && (
                            <MainServicesWrapper>
                                <h1 className="header">
                                    {t('pages.agency.extra_services.consultationsPackagesTitle').text}
                                </h1>
                                <div className="cardWrapper">
                                    {consultationPackagesArray.map((separatePackage: any, index: number) => (
                                        <ConsultationsCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys[], chosenPackageId: string) => {
                                                setConsultationPreCartItems((prev) => ({
                                                    ...prev,
                                                    [chosenPackageId]: chosenServices, // Add or replace services for this package
                                                }));
                                            }}
                                            removePackage={(chosenServices: ObjectKeys[], chosenPackageId: any) => {
                                                setConsultationPreCartItems((prev) => {
                                                    const updatedItems = { ...prev };
                                                    delete updatedItems[chosenPackageId]; // Remove services for this package
                                                    return updatedItems;
                                                });
                                            }}
                                            nextStep={(consultationServiceID: string, selectedOtion: string) => {
                                                if (selectedOtion === '') {
                                                    setConsultationPackageValidationErrorId(consultationServiceID);
                                                    return;
                                                }
                                                const itemNames = Object.keys(consultation_pre_cart_items);

                                                const filteredId = itemNames.find(
                                                    (item: string) => item === consultationServiceID,
                                                );
                                                resetPreCarts();
                                                resetAllRadioButtons();
                                                if (filteredId) {
                                                    const new_services = (
                                                        consultation_pre_cart_items as Record<string, any>
                                                    )[filteredId];
                                                    if (services) {
                                                        updateCartAdd(new_services, filteredId);
                                                    }

                                                    setConsultationPackageValidationErrorId('');
                                                    setConsultationPreCartItems([]);

                                                    toast.success(
                                                        <div>
                                                            {t('pages.agency.subscription.serviceAddedToCart').text}
                                                            <button
                                                                onClick={() => {
                                                                    resetPreCarts();
                                                                    navigate('/guest/2', {
                                                                        state: { fromTab: 'packages', tabIndex: 0 }, // Passing state
                                                                    });
                                                                }}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color: 'var(--purple)',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {t('pages.agency.subscription.goToCart').text}
                                                            </button>
                                                        </div>,
                                                        { autoClose: 5000 }, // Keeps the toast visible for 5s
                                                    );
                                                } else {
                                                    setConsultationPackageValidationErrorId(consultationServiceID);
                                                }
                                            }}
                                            emptyCartError={consultationPackageValidationErrorId === separatePackage.id}
                                            resetSelectionSignal={resetSignal}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 2 && (
                            <MainServicesWrapper>
                                <h1 className="header">
                                    {t('pages.agency.extra_services.separateServicesTitle').text}
                                </h1>
                                <div className="select-all">
                                    <div className="leftSide">
                                        <Checkbox defaultChecked={selectAllChecked} onChange={toggleSelectAll} />
                                        <p>{t('pages.agency.extra_services.select_all').text}</p>
                                    </div>
                                </div>
                                {services && filteredServices?.length > 0 ? (
                                    <FilteredContainer>
                                        {filteredServices.map((row: any) => {
                                            return (
                                                <>
                                                    <div
                                                        key={row.id}
                                                        className="rowHover"
                                                        onClick={() => handleServiceClick(row.id)}
                                                        style={{
                                                            backgroundColor:
                                                                expandedServiceId === row.id ? '#cebcff' : '',
                                                        }}
                                                    >
                                                        <span className="left" key={renderKey}>
                                                            <Checkbox
                                                                defaultChecked={plainServicesPreCart.some(
                                                                    (service) => service.id === row.id,
                                                                )}
                                                                onChange={() => handleCheckbox(row)}
                                                            />
                                                            <span>{row[`name_${lang}`]}</span>
                                                        </span>
                                                        <div className="counter-parent">
                                                            <span className="right">
                                                                <span>
                                                                    {formatTwoDecimals(row.price.toString())} RSD
                                                                </span>
                                                            </span>
                                                            <div className="counter">
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterDecrease(row);
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                                <span>{getQuantityForService(row)}</span>
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterIncrease(row);
                                                                    }}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {expandedServiceId === row.id && (
                                                        <div className="description">{row[`description_${lang}`]}</div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </FilteredContainer>
                                ) : (
                                    <p>{t('pages.agency.extra_services.no_services').text}</p>
                                )}
                            </MainServicesWrapper>
                        )}
                    </div>
                    {validationError && tabIndex === 2 && cart.items.length === 0 ? (
                        <p className="validation-error">{t('pages.agency.extra_services.one_service_error').text}</p>
                    ) : null}
                    {tabIndex === 2 && (
                        <div className="services-footer-wrapper">
                            <div className="info">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <p>{t('pages.agency.extra_services.click_more_info').text}</p>
                            </div>
                        </div>
                    )}
                    <div className="services-footer-wrapper">
                        <StepControlButton
                            step={'login'}
                            handleClick={() => {
                                navigate('/login');
                            }}
                        />
                        {tabIndex === 2 && (
                            <Button
                                variant={ButtonVariant.solid}
                                color={'var(--purple)'}
                                onClick={() => {
                                    if (plainServicesPreCart.length === 0) {
                                        setEmptyCardModal(true);
                                        return;
                                    } else {
                                        movePreCartToCart();
                                    }
                                }}
                                // size={'15%'}
                            >
                                {t('pages.agency.subscription.addToCart').text}
                            </Button>
                        )}

                        {/* <StepControlButton
                            step={'cart'}
                            handleClick={() => {
                                if (cart.items.length === 0) {
                                    setValidationError(true);
                                    return;
                                } else {
                                    setValidationError(false);
                                    nextStep();
                                    navigate('/guest/2', {
                                        state: { fromTab: 'all-services', tabIndex: 0 }, // Passing state
                                    });
                                }
                            }}
                        /> */}
                    </div>
                </ChooseFeature>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    background: white;
    padding: 30px;
    p {
        font-weight: 400;
    }
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        padding: 0 0 1rem;
    }

    @media screen and (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 89.8vh;
    }
    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        min-height: 70vh;
    }
`;
const FilteredContainer = styled.div`
    max-height: 470px;
    overflow-y: auto;
    width: 100%;

    /* Styles for WebKit browsers */
    &::-webkit-scrollbar {
        width: 12px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #9b7fe9; /* Color of the track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a0a0a0; /* Color of the scrollbar thumb */
        border-radius: 6px; /* Roundness of the scrollbar thumb */
        border: 3px solid #9b7fe9; /* Creates padding around the scrollbar thumb */
    }

    /* Styles for Firefox */
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #9b7fe9 #f0f0f0; /* Thumb and track color */
`;
const ChooseFeature = styled.div`
    padding: 20px 0;
    text-align: center;
    @media only screen and (max-width: ${breakpoints.laptopM - 1}px) {
        padding: 0;
    }
    .select-all {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--purple);
        width: 100%;
        p {
            font-size: 14px;
            cursor: default;
        }

        .leftSide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .ad {
        text-align: left;
        padding-bottom: 10px;
        color: var(--purple);
        @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
            padding-bottom: 0;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            padding: 0 10px;

            @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
                padding: 20px 10px;
            }

            @media screen and (min-width: ${breakpoints.tablet}px) {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
    .notes {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .search-services {
            background-color: var(--purple);
            margin-bottom: 20px;
            text-align: -webkit-center;
            width: 100%;
            color: var(--white);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            height: 65px;
            padding: 10px 20px;
            font-size: 14px;
            text-transform: uppercase;
            .tabs {
                display: flex;
                gap: 20px;
                flex-direction: column;

                @media screen and (min-width: ${breakpoints.tablet}px) {
                    flex-direction: row;
                }
            }
            @media screen and (max-width: ${breakpoints.tablet - 1}px) {
                align-items: center;
                justify-content: center;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                align-items: center;
                flex-direction: column;
                gap: 0.5rem;
                height: auto;
            }
            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px) {
                flex-direction: row;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            .title-tooltip-wrapper {
                display: flex;
                align-items: center;
                gap: 1rem;
                .chooseService {
                    color: var(--white);
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .tooltip-wrapper {
                    div {
                        > span {
                            background-color: var(--white);
                            color: var(--purple);
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            display: block;
                            position: relative;
                            &::after {
                                content: '?';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-weight: 900;
                                font-size: 17px;
                            }
                            span {
                                width: 200px;
                            }
                        }
                    }
                }
            }

            .search-input {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                padding: 0 10px;
                background-color: var(--white);
                width: 100%;
                @media only screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
                    width: 30%;
                    justify-content: start;
                }
                @media only screen and (min-width: ${breakpoints.laptop - 1}px) {
                    width: auto;
                }
                svg {
                    color: var(--gray);
                    font-size: 15px;
                }
                .input {
                    width: 100%;
                    .input-container {
                        margin-bottom: 0;
                        input {
                            border-bottom: 0;
                            font-size: 15px;
                        }
                    }
                }
            }

            .serviceHeader {
                font-size: 14px;
                padding: 10px 20px;
                cursor: pointer;

                p {
                    font-size: 14px;
                }

                @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                    display: flex;
                    align-items: center;
                }
            }

            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                .smallerPadding {
                    padding: 0;
                }
            }

            .itemSelected {
                border-bottom: 3px solid var(--white);
                padding-top: 1px;
                padding: 10px 20px;
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            &:last-child {
                border-bottom: none;
            }
        }
        .rowHover {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            :hover {
                cursor: pointer;
                background-color: #cebcff;
                .left,
                .right {
                    color: var(--black);
                }
            }
        }
        .description {
            padding: 5px 5% 5px 4%;
            border-radius: 20px;
            margin-top: 5px;
            font-size: 13px;
            text-align: justify;
            font-style: italic;
        }
        .left {
            text-align: left;
            display: flex;
            max-width: 280px;
            color: var(--gray);
            font-size: 15px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 150px;
            }
            > div {
                > div {
                    margin-bottom: 0;
                }
            }
        }
        .right {
            text-align: right;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            color: var(--gray);
            font-size: 15px;
            .crosedText {
                text-decoration: line-through;
            }
        }
        .counter {
            display: flex;
            align-items: center;
            span {
                padding: 0 5px;
                border: 2px solid var(--purple);
            }
        }
        .counterBtn {
            border: none;
            padding: 5px 8px;
            cursor: pointer;
            margin: 0 5px;
            color: var(--purple);
            font-weight: bold;
            background-color: transparent;
        }
        .counterBtn:hover {
            color: var(--white);
        }
        .counter-parent {
            display: flex;
            align-items: center;
        }
    }
    .validation-error {
        color: red;
        margin-top: 1.5rem;
        font-size: 14px;
    }
    .services-footer-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            gap: 1.5rem;
        }

        button {
            font-size: 12px;
            padding: 9px 16px;
            height: 35px;
        }
        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: ${colors.purple};
            justify-content: flex-start;
            p {
                font-size: 14px;
            }
        }
    }
`;

const MainServicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 50vh;

    .header {
        font-weight: 400;
        font-size: 32px;
        margin: 20px 0 40px 0;
    }

    .cardWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        @media screen and (min-width: ${breakpoints.desktop}px) {
            justify-content: left;
        }
        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            justify-content: center;
        }
    }
`;

export default StepOne;

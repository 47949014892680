import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useTheme from '../../hooks/useTheme';

interface Props {
    close: Function;
    active?: boolean;
    className?: string;
    children?: string | any;
}

const Conditions: FunctionComponent<Props> = ({ close, children }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const isDarkTheme = useTheme();

    // The openInNewTab function is not needed because the same functionality
    // can be achieved directly using an anchor (<a>) element with the 'target' attribute set to '_blank'.
    const openInNewTab = (link: string): void => {
        const baseUrl = window.location.origin;
        const url = new URL(link, baseUrl);
        window.open(url.href, '_blank');
    };

    return (
        <>
            <TextContainer>
                <h3>{t('pages.invoices.conditions.newCardPaymentNotification-pt-1').text}</h3>
                <h3>
                    {t('pages.invoices.conditions.newCardPaymentNotification-pt-2').text}{' '}
                    <span onClick={() => openInNewTab('Uslovi_plaćanja.pdf')} className="link">
                        {t('pages.invoices.conditions.newCardPaymentNotification-pt-3').text}
                    </span>
                </h3>
            </TextContainer>
            <div>{children}</div>
            <ButtonContainer isDarkTheme={isDarkTheme}>
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => close()} size={'100%'}>
                    {t('pages.invoices.conditions.accept').text}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default Conditions;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 35px 10px;
    }
    h3 {
        padding-top: 0px;
        margin-top: 10px;
        color: var(--black);
        font-size: 20px;
    }
    .link {
        font-size: 20px;
        cursor: pointer;
        text-decoration: underline;
        :hover {
            color: black;
        }
    }
`;

const ButtonContainer = styled.div<DarkThemeProps>`
    button {
        border-radius: 0px !important;
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
`;

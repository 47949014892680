import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useTheme from '../../hooks/useTheme';

interface Props {
    close: Function;
    message?: string;
    active?: boolean;
    className?: string;
    children?: string | any;
}

const Success: FunctionComponent<Props> = ({ close, message, children }) => {
    const isDarkTheme = useTheme();

    return (
        <>
            {message && (
                <TextContainer>
                    <h3>{message}</h3>
                </TextContainer>
            )}
            <div>{children}</div>
            <ButtonContainer isDarkTheme={isDarkTheme}>
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => close()} size={'100%'}>
                    ok
                </Button>
            </ButtonContainer>
        </>
    );
};

export default Success;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div<DarkThemeProps>`
    button {
        border-radius: 0px !important;
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
`;
